import * as React from 'react';
import '../css/navbar.css';
import { AppBar, Box, Toolbar, Typography, Link } from "@mui/material";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authconfig';
import '../css/site.css';
import { UserContext } from '../UserContext';
import { eUserType } from '../Enums';

function NavMenu(props: any) {

  const { instance, accounts } = useMsal();

  const { user } = React.useContext(UserContext);

  return (
    <div>
      <AppBar position="static" style={{ background: '#002F6C', height: 56 }}>
        <Toolbar>

          <Typography variant="h6" className="maintitle">
            <img src="https://nplstdigitaldev001.blob.core.windows.net/npl-blob-application/npl-header-logo.png" alt="NPL Logo" />
            <span className="caption">Members Portal</span>
          </Typography>

          <Box sx={{ marginLeft: '10px' }}>
            <Typography>Welcome {accounts[0]?.name}</Typography>
          </Box>

          <div className="nav_links">

            <AuthenticatedTemplate>
              <ul className="nav_links">
                <li><button onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}>Sign out</button></li>
              </ul>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <ul className="nav_links">
                <li><button onClick={() => instance.loginRedirect(loginRequest)}>Sign in</button></li>
              </ul>
            </UnauthenticatedTemplate>
          </div>

        </Toolbar>
      </AppBar>

    </div>

  );

}

export default NavMenu;
