import { createContext } from "react";
import { INPLUser } from "./Interfaces";


export type GlobalContent = {
    user: INPLUser,
    setUser:(user: INPLUser) => void
  }

 export const UserContext = createContext<GlobalContent>( {
     user: {} as INPLUser,
        setUser: (user: INPLUser) => {},
    });


//  export const UserGlobalContext = () => useContext(UserContext)
