import { Avatar } from "@mui/material";

interface Props {
    name?: string;
}

export default function InitialsIcon({name = 'A A'}:Props) {
    function stringToColor(string: string) {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    }

    function stringAvatar(name: string) {
        if (name.split(' ').length <= 0) return null;


        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: (name.split(' ').length <= 1) ? `${name.split(' ')[0][0]}`.toUpperCase() :
                `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toUpperCase(),
        };
    }

    return <Avatar {...stringAvatar(name)} />;
}
