import { Box, Container, Grid } from "@mui/material";

const AccessDenied = () => {
	return (
		<div>
			<Box className="layout">
				<Container fixed>
					<Grid container className="titlePadding">
						<Grid item xs={12} className="title">
							Members Portal
						</Grid>
						<Grid item xs={12} className="subtitle">
							Access Denied
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Container className="middleContainer">
				<Box>
					<p>You are not authorized to access this page.</p>
					<p>Please contact the system administrator if you believe this is in error.</p>
				</Box>
			</Container>
		</div>
	);
};

export default AccessDenied;
