import { Grid } from '@mui/material';
import '../css/application.css';
import '../css/site.css';
import { useLocation } from 'react-router-dom';

export default function Home() {
  const location = useLocation();

  const showSignInPrompt = location.pathname.includes("registration") || location.pathname.includes("completeExtendedReg")

  const textToShow = showSignInPrompt ? "You're nearly signed up - please click \"sign in\" and select the organisation/application to complete the extended registration." : ""

  return <Grid className="middleContainer">
    <Grid item xs={12} style={ showSignInPrompt ? {backgroundColor: "rgb(0,94,148)", minHeight: "80vh"}: {}}>
      {!showSignInPrompt && <img src="https://nplstdigitaldev001.blob.core.windows.net/npl-blob-application/ListingPortal.png" alt="" width="100%" min-height="100%" /> }

      <div id="hompagetext">{textToShow}</div>
    </Grid>
  </Grid>
}
