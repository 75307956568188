import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface DeleteProps {
    title: string;
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

export function ApprovalDialog({ title, open, onConfirm, onClose }: DeleteProps) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                Approve {title}?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to approve {title} for access to the system?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>Reject</Button>
                <Button variant="contained" onClick={onConfirm} autoFocus>Approve</Button>
            </DialogActions>
        </Dialog>
    );
}

interface DeniedProps {
    open: boolean;
    onClose: () => void;
}

export function DeniedDialog({ open, onClose }: DeniedProps) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                Permission Denied
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You do not have sufficient permissions to perform this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose} autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    );
}
