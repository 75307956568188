import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Home from "./components/Home";
import NavMenu from "./components/Navmenu";
import "./css/site.css";
import { Footer } from "./components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { loginRequest } from "./authconfig";

function Layout(props: any) {
	const { instance } = useMsal();
	const { pathname, search } = useLocation();
	const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate()

	if (!isAuthenticated && pathname.includes("/completeExtendedReg")) {
    instance.loginRedirect(loginRequest);
	}

  if(isAuthenticated && pathname.includes("/completeExtendedReg")) {
    const params = new URLSearchParams(search);
		const app = params.get("app");
    navigate(`/registration?app=${app}`)
  }

	let SubLayout = (
		<div style={{ flex: 1 }}>
			<UnauthenticatedTemplate>
				<Home />
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
		</div>
	);

	return (
		<div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
			<NavMenu />

			{SubLayout}

			<Footer />
		</div>
	);
}

//Layout.contextType = AuthContext;
export default Layout;
