import axios, { AxiosResponse } from 'axios';
import type { ICompany, INPLApp, INPLUser, IRegistrationApplication, IRole, IUserRegistration, Registration} from '../Interfaces';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    getWithToken: <T>(url: string, accessToken: string) => axios.get<T>(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    postWithToken: (url: string, body: {}, accessToken: string) => axios.post(url, body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then(responseBody),
    postWithTokenFull: (url: string, body: {}, accessToken: string) => axios.post(url, body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    putWithToken: (url: string, body: {}, accessToken: string) => axios.put(url, body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then(responseBody),
    del: (url: string) => axios.delete(url).then(responseBody),
    delWithToken: (url: string, body: {}, accessToken: string) => axios.delete(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then(responseBody),
}

const application = {
    getAll: (accessToken: string) => requests.getWithToken<INPLApp[]>('/Application', accessToken),
    getAppRoles: (applicationId: number, accessToken: string) => requests.getWithToken<IRole[]>(`/Application/${applicationId}/Roles`, accessToken),
    getApplication: (id: number, accessToken: string) => requests.getWithToken<INPLApp>(`/Application/${id}`, accessToken)
}

const details = {
    getCompanies: (accessToken: string) => requests.getWithToken<ICompany[]>('/User/GetCompanies', accessToken),
}

const user = {
    applicationUsers: (applicationId: number, accessToken: string) => requests.getWithToken<INPLUser[]>(`/User/${applicationId}`, accessToken),
    getUser: (accessToken: string) => requests.getWithToken<INPLUser>(`/User/GetUserForWeb/`, accessToken),
    approveUser: (user: IRegistrationApplication, accessToken: string) => (requests.postWithTokenFull(`/User/ApproveUser`, user, accessToken)),
    updateUser: (user: IUserRegistration, accessToken: string) => (requests.postWithToken(`/User`, user, accessToken)),
    submitAppRegistration: (registration: Registration, accessToken: string) => (requests.postWithToken(`/User/${registration.userId}`, registration, accessToken)),
    setUserRole: (user: IRegistrationApplication, accessToken: string) => (requests.postWithToken(`/User/${user.userId}/Roles`, user, accessToken))
}

const agent = {
    application,
    details,
    user
}

export default agent;

